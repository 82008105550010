<template>
  <div class="announcement" @click.stop>
    <h3>{{ title }}</h3>
    <div class="content">{{ content }}</div>
    <div class="submit_btn" @click="confirm()">我知道了</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "公告",
      content:
        "等哈说了很大机会大家说的叫啥都加上客户豆沙红豆沙回家的好时机大家的哈时间和大家出报表显示充电宝充电宝充电宝充电宝觉得好的成本和待补充待补充待补充待补充大家恢复重建的传播速度和传播的好处吧等哈说了很大机会大家说的叫啥都加上客户豆沙红豆沙回家的好时机大家的哈时间和大家出报表显示充电宝充电宝充电宝充电宝觉得好的成本和待补充待补充待补充待补充大家恢复重建的传播速度和传播的好处吧等哈说了很大机会大家说的叫啥都加上客户豆沙红豆沙回家的好时机大家的哈时间和大家出报表显示充电宝充电宝充电宝充电宝觉得好的成本和待补充待补充待补充待补充大家恢复重建的传播速度和传播的好处吧等哈说了很大机会大家说的叫啥都加上客户豆沙红豆沙回家的好时机大家的哈时间和大家出报表显示充电宝充电宝充电宝充电宝觉得好的成本和待补充待补充待补充待补充大家恢复重建的传播速度和传播的好处吧等哈说了很大机会大家说的叫啥都加上客户豆沙红豆沙回家的好时机大家的哈时间和大家出报表显示充电宝充电宝充电宝充电宝觉得好的成本和待补充待补充待补充待补充大家恢复重建的传播速度和传播的好处吧 都会变成待补充待补充待补充大理石板材厚度是不错的火爆程度等会吃饭吧都会变成达坂城达坂城达坂城的话差不多哈大红唇白话达坂城达坂城达坂城的随便吃点吧v",
    };
  },
  methods: {
    confirm() {
      let couponCheck =JSON.parse(localStorage.getItem("couponCheck")) 
      let date =  Date.now()
      if (couponCheck.data && date < couponCheck.expires) {
        this.$router.push("/main")
      }else{
        console.log(date,couponCheck.expires,date < couponCheck.expires);
        this.$router.push("/coupon");
      }
      // couponCheck ? this.$router.push("/main") : this.$router.push("/coupon");
    },
  },
};
</script>

<style scoped lang="scss">
.announcement {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 8.5rem;
  width: 6rem;
  border-radius: 0.3rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  transition: 0.3s;
  background-color: #fff;
  padding: 0 0.2rem;
}
.content {
  height: 6rem;
  overflow: auto;
}
.submit_btn {
  margin: 0.2rem 0;
  width: 90%;
  line-height: 0.7rem;
  text-align: center;
  background-color: $color-primary;
  color: #fff;
  border-radius: 1rem;
}
</style>